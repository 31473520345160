import React from "react";
import pkg from "@base/package.json";
import { Layout, Menu, Dropdown, Avatar } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "@contexts";
import {
  TeamOutlined,
  UserOutlined,
  GlobalOutlined,
  LogoutOutlined,
  DashboardOutlined,
  SettingOutlined,
  TagsOutlined,
  PicLeftOutlined,
  ProfileOutlined,
  FileImageOutlined,
  LineChartOutlined,
  SolutionOutlined,
} from "@ant-design/icons";

const { Header, Content, Footer, Sider } = Layout;

export default function Moderator({ children, page, ...props }) {
  const { currentUser } = useAuth();

  return (
    <Layout hasSider={false} style={{ minHeight: "100vh" }}>
      <Layout
        hasSider={false}
        className="ml-16 lg:ml-52"
        style={{
          backgroundColor: "#fafafb",
        }}
      >
        <Header className="bg-white shadow-sm flex justify-end items-center p-5 z-50 h-[70px] sticky top-0 w-full m-0">
          <Dropdown
            menu={{
              className: "min-w-44 max-w-xs",
              items: [
                {
                  key: "profile",
                  type: "group",
                  label: (
                    <div className="w-full p-0">
                      <span className="break-words block mb-1 w-full text-neutral-400 font-normal text-xs">
                        ID {currentUser.id} &middot; {currentUser.email}
                      </span>
                    </div>
                  ),
                },
                {
                  key: "divider",
                  type: "divider",
                },
                {
                  key: "settings",
                  icon: <SettingOutlined />,
                  label: (
                    <Link to={`/users/edit/${currentUser?.id}`}>
                      Настройки профиля
                    </Link>
                  ),
                },
                {
                  key: "divider",
                  type: "divider",
                },
                {
                  key: "logout",
                  icon: <LogoutOutlined />,
                  label: (
                    <Link className="transition-none" to="/logout">
                      Выйти
                    </Link>
                  ),
                  danger: true,
                },
              ],
            }}
          >
            <div className="flex items-center">
              <Avatar
                style={{ cursor: "pointer" }}
                icon={<UserOutlined />}
                size="large"
              />
              <div className="flex flex-col justify-center items-start ml-3 text-left cursor-pointer">
                <span className="text-base font-medium text-slate-900 leading-none m-0">
                  {currentUser?.name}
                </span>
                <span className="text-sm text-neutral-400 leading-none mt-1">
                  Администратор
                </span>
              </div>
            </div>
          </Dropdown>
        </Header>
        <Sider
          // className="bg-white pt-5"
          theme="dark"
          className="pt-5"
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            bottom: 0,
          }}
          breakpoint="lg"
          collapsedWidth="64"
          width={208}
        >
          <img
            src="/img/symbol.svg"
            className="block md:hidden w-full object-center object-contain mb-5 px-2"
            alt="Kapital.kz"
          />

          <img
            src="/img/logo-light.svg"
            className="hidden lg:block w-full object-center object-contain px-5 mb-5"
            alt="Kapital.kz"
          />

          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[page]}
            defaultOpenKeys={["dict", "blog"]}
            items={[
              {
                key: "dashboard",
                icon: <DashboardOutlined />,
                label: (
                  <Link className="transition-none" to="/">
                    Главная
                  </Link>
                ),
              },
              {
                key: "articles",
                icon: <PicLeftOutlined />,
                label: (
                  <Link className="transition-none" to="/articles">
                    Материалы
                  </Link>
                ),
              },
              {
                key: "projects",
                icon: <GlobalOutlined />,
                label: (
                  <Link className="transition-none" to="/projects">
                    Спецпроекты
                  </Link>
                ),
              },
              {
                key: "categories",
                icon: <ProfileOutlined />,
                label: (
                  <Link className="transition-none" to="/categories">
                    Рубрики
                  </Link>
                ),
              },
              {
                key: "tags",
                icon: <TagsOutlined />,
                label: (
                  <Link className="transition-none" to="/tags">
                    Теги
                  </Link>
                ),
              },
              {
                key: "people",
                icon: <SolutionOutlined />,
                label: (
                  <Link className="transition-none" to="/people">
                    Персоны
                  </Link>
                ),
              },
              {
                key: "media",
                icon: <FileImageOutlined />,
                disabled: true,
                label: "Медиа",
              },
              {
                key: "settings",
                icon: <SettingOutlined />,
                label: "Настройки",
                children: [
                  {
                    key: "global",
                    label: (
                      <Link className="transition-none" to="/settings">
                        Настройки сайта
                      </Link>
                    ),
                  },
                  {
                    key: "contacts",
                    label: (
                      <Link className="transition-none" to="/settings/contacts">
                        Контакты
                      </Link>
                    ),
                  },
                  {
                    key: "agreement",
                    label: (
                      <Link
                        className="transition-none"
                        to="/settings/agreement"
                      >
                        Соглашения
                      </Link>
                    ),
                  },
                  {
                    key: "editorial",
                    label: (
                      <Link
                        className="transition-none"
                        to="/settings/editorial"
                      >
                        Редакция
                      </Link>
                    ),
                  },
                  {
                    key: "advertising",
                    label: (
                      <Link
                        className="transition-none"
                        to="/settings/advertising"
                      >
                        Реклама
                      </Link>
                    ),
                  },
                ],
              },
              {
                key: "meta",
                icon: <LineChartOutlined />,
                label: (
                  <Link className="transition-none" to="/meta">
                    SEO
                  </Link>
                ),
              },
              {
                key: "users",
                icon: <TeamOutlined />,
                label: (
                  <Link className="transition-none" to="/users">
                    Пользователи
                  </Link>
                ),
              },
            ]}
          />
        </Sider>
        <Content
          style={{
            overflow: "initial",
          }}
          className="mx-0 my-4 lg:m-4"
        >
          <div className="bg-none p-0 md:lg-p-3 lg:p-6">{children}</div>
        </Content>
        <Footer
          style={{
            backgroundColor: "#fafafb",
            textAlign: "center",
          }}
        >
          Kapital.kz © {new Date().getFullYear()} | Разработан{" "}
          <a
            href="https://musan.kz"
            alt="Musan Digital"
            title="Musan Digital"
            rel="noreferrer"
            target="_blank"
          >
            Musan Digital
          </a>{" "}
          | Версия {pkg.version}
        </Footer>
      </Layout>
    </Layout>
  );
}
