import { Form, Input } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="subtitle"
      className="w-full"
      label="Подзаголовок"
    >
      <Input.TextArea
        size="large"
        controls={false}
        minLength={3}
        rows={3}
        className="w-full font-medium text-black"
        placeholder="Введите подзаголовок"
      />
    </Form.Item>
  );
}
