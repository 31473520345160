import { Card, Badge, Tooltip, Tag } from "antd";
import { ARTICLE_STATUSES } from "@utils/dict";
import Details from "./details";
import Editor from "./editor";
import Photos from "./photo";
import Actions from "./actions";
import StepByStep from "./stepbystep";
import TTS from "./tts";
import Meta from "./meta";
import SEO from "./seo";

export default function EditEntry({ data, form, editorInstance, loading }) {
  const type = form.getFieldValue("type");

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 xl:col-span-8 flex flex-col gap-4">
        <Card
          title={
            <div className="flex flex-wrap items-center gap-y-1 gap-x-2 my-2">
              Основная информация <Tag className="m-0">ID: {data?.id}</Tag>
            </div>
          }
          bordered={false}
          extra={
            <Tooltip title="Текущий статус материала">
              <Badge
                text={ARTICLE_STATUSES[data?.status]?.label}
                status={ARTICLE_STATUSES[data?.status]?.badgeStatus}
                className="m-0"
              />
            </Tooltip>
          }
        >
          <Details data={data} form={form} />
        </Card>
        <Card title="Фото" bordered={false}>
          <Photos data={data} form={form} />
        </Card>
        <Card title="Контент" bordered={false}>
          {type?.id === 7 ? (
            <StepByStep data={data} form={form} />
          ) : (
            <Editor editorInstance={editorInstance} data={data} form={form} />
          )}
        </Card>
      </div>
      <div className="col-span-12 xl:col-span-4 flex flex-col gap-4">
        <Actions loading={loading} data={data} form={form} />
        <TTS editorInstance={editorInstance} data={data} form={form} />
        <Meta editorInstance={editorInstance} data={data} form={form} />
        <SEO form={form} />
      </div>
    </div>
  );
}
