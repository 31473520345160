import { Form, Input } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="h1_override"
      className="w-full"
      label="H1 заголовок"
    >
      <Input
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Введите H1 заголовок"
      />
    </Form.Item>
  );
}
